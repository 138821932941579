*{
  font-family: 'Poppins', sans-serif;
}
.album-cover-wpr2{
  display: none !important;
  background: none !important;
}

.repro-cont{
  position: absolute;
  top: -375px;
  right: 0;
}

/* .player-ctr{
  background: #22cb1c  !important;
  filter: invert(36%) sepia(90%) saturate(7045%) hue-rotate(308deg) brightness(80%) contrast(117%) !important;
} */

.tittle-container-brip{
  text-align: center;
  color: white;
  font-size: 1.2em;
  font-weight: 600;
  line-height: 1em;
}

h5{
  font-size: 1em;
  line-height: 1.2em;
}
body{
  background-image: none !important;
}

.container{
  height: 100vh;
  display: flex;
  justify-content: center;
}

.container-general{
  width: 100%;
  background: #202020;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.container-reproductor{
  height: 200px;
}
  
.logo-container{
  width: 50%;
  text-align: center;
  align-items: center;
}

.logo-brip{
  width: 350px;
  height: 350px;
}

.container-redes{
  width: 70%;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.redes-child{
  width: 32%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
}

.redes-child a{
  color: white;
  text-decoration: none;
}

.icon-style{
  font-size: 1.2em;
  color: white;
}

.icon-style-mail{
  color: white;
}

.imagen-pais{
  width: 10%;
  padding-right: 10px;
}

.nombre-redes{
  padding-left: 10px;
}

@media (max-width: 768px) {
  .repro-cont{
    position: absolute;
    top: -375px;
    right: 9%;
  }
  h2{
    font-size: 1.3em;
  }
  h5{
    font-size: 1em;
    line-height: 1.2em;
    padding-bottom: 15px;
  }
  .container-redes{
    flex-direction: column;
  }

  .redes-down{
    padding: 30px 0px !important;
  }
  .tittle-container-brip{
    width:85%;
    text-align: center;
    color: white;
    font-size: 1em;
    font-weight: 500;
    line-height: 1em;
  }
  .logo-brip {
    width: 100%;
    height: 100%;
  }
  
  .redes-child{
    width: 100%;
    padding: 10px 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
  }
  a{
    font-weight: 500;
  }
}




